<template>
  <div class="sld_phone_mange">
    <MemberTitle :memberTitle="L['我的问诊']"></MemberTitle>
    <div class="container">
      <div v-if="!isDetails">
        <div class="title">{{ L['我的问诊'] }}</div>
        <div class="mange_con">
          <el-table :data="tableData.data" border style="width: 100%;margin: 0 auto">
            <el-table-column prop="prescriptionSn" label="处方单号"></el-table-column>
            <el-table-column prop="patientName" label="姓名"></el-table-column>
            <el-table-column prop="prescriptionDiagnosis" label="诊断"></el-table-column>
            <el-table-column prop="prescriptionDate" label="开具时间"></el-table-column>
            <el-table-column class="operate" prop="operate" label="操作" width="100">
              <template #default="scope">
              <el-button @click="viewMore(scope.row)" type="text" >查看</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-if="isDetails">
        <div class="more_title" @click="goBack"><span>&lt;返回</span></div>
        <div class="suggest">
          <h2 style="margin-bottom: 15px">用药建议</h2>
          <div class="title">基础信息</div>
          <div class="basic">
            <div>
              <text>NO：</text>
              <text>{{ suggestList.data.prescriptionSn }}</text>
            </div>
            <div style="width: 340px" class="people_details">
                <span>
                  <text>姓名：</text>
                  <text>{{ suggestList.data.patientName }}</text>
                </span>
              <span>
                  <text>性别：</text>
                  <text>{{ peopleSex }}</text>
                </span>
              <span>
                  <text>年龄：</text>
                  <text>{{ suggestList.data.patientAge }}</text>
                  <text>岁</text>
                </span>
            </div>
            <div style="width: 254px;margin-bottom: 20px" class="people_details">
                <span>
                  <text>科室：</text>
                  <text>{{ suggestList.data.doctorDept }}</text>
                </span>
              <span>
                  <text>日期：</text>
                  <text>{{ suggestList.data.prescriptionDate }}</text>
                </span>
            </div>
            <div>
              <h3>诊断</h3>
              <div style="margin-top: 10px">
                {{ suggestList.data.prescriptionDiagnosis }}
              </div>
            </div>
          </div>
          <div class="title">用药建议</div>
          <div>
            <div class="margins">
              <text v-if="suggestList.data.orderPrescriptionMedicineListVOList[0]" >{{ suggestList.data.orderPrescriptionMedicineListVOList[0].medicineName }}</text>
            </div>
            <div class="margins">
              <text>规格：</text>
              <text v-if="suggestList.data.orderPrescriptionMedicineListVOList[0]">{{ suggestList.data.orderPrescriptionMedicineListVOList[0].medicineSpec }}</text>
            </div>
            <div class="margins" v-if="suggestList.data.orderPrescriptionMedicineListVOList[0]">
              {{suggestList.data.orderPrescriptionMedicineListVOList[0].medicineUsage + suggestList.data.orderPrescriptionMedicineListVOList[0].medicineDosage }}
            </div>
            <div class="margins">
              <text>剂型：</text>
              <text v-if="suggestList.data.orderPrescriptionMedicineListVOList[0]" style="margin-left: 40px">{{ suggestList.data.orderPrescriptionMedicineListVOList[0].medicineForm }}</text>
            </div>
            <div class="margins">
              <h4>补充说明</h4>
              <div v-if="suggestList.data.orderPrescriptionMedicineListVOList[0]" style="margin-top: 8px">{{ suggestList.data.orderPrescriptionMedicineListVOList[0].prescriptionAdditional }}</div>
            </div>
            <div class="margins">
              <h4>签字盖章</h4>
              <div class="bottoms">
                <div class="last">
                  <text style="vertical-align: middle">医生：</text>
                  <img style="width: 200px;height: 200px;vertical-align: middle;" :src="suggestList.data.doctorSign">
                </div>
                <div class="last">
                  <text style="vertical-align: middle">药师：</text>
                  <img style="width: 200px;height: 200px;vertical-align: middle" :src="suggestList.data.pharmacistSign">
                </div>
                <div class="last">
                  <text style="vertical-align: middle">药店审核：</text>
                  <img style="width: 200px;height: 200px;vertical-align: middle" :src="suggestList.data.pharmacySign">
                </div>
              </div>
            </div>
          </div>
          <div class="refer">
            页面内容仅供参考，请在专业医师指导下进行
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ElInput, ElMessage, ElMessageBox} from "element-plus";
import {getCurrentInstance, onMounted, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import MemberTitle from "../../components/MemberTitle";
import {useRoute} from "vue-router";

export default {
  name: "PhoneMange",
  components: {
    ElInput,
    MemberTitle
  },
  setup() {
    const peopleSex = ref('')
    const {proxy} = getCurrentInstance();
    const errorMsg = ref("");
    const L = proxy.$getCurLanguage();
    const store = useStore();
    const route = useRoute();
    const isDetails = ref(false)
    const tableData = reactive({data: []});
    const suggestList = reactive({data: {}});

    const goBack = () => {
      isDetails.value = false
    }
    const viewMore = (value) => {
      isDetails.value = true
      suggestList.data = value
      peopleSex.value = suggestList.data.patientSex % 2 == 1 ? '男' : '女';
    }

    // 获取处方列表
    const getPrescriptionList = () => {
      proxy.$get("v3/business/front/OrderPrescription/getPrescription", {
        orderSn: route.query.orderSn
      }).then(res => {
        if (res.state == 200) {
          tableData.data = res.data;
        } else {
          ElMessage(res.msg);
        }
      }).catch(() => {
            //异常处理
          });
    }

    onMounted(() => {
      getPrescriptionList()
    })

    return {
      L,
      errorMsg,
      isDetails,
      tableData,
      suggestList,
      peopleSex,
      goBack,
      getPrescriptionList,
      viewMore,
    };
  }
};
</script>

<style lang="scss" scoped>
.sld_phone_mange {
  width: 1007px;
  float: left;
  margin-left: 10px;

  .container {
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #eaeaea;
    padding: 25px 40px;

    .title {
      font-size: 18px;
      border-bottom: 1px dashed #eaeaea;
      padding-bottom: 25px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .mange_con {
      margin: 0 auto;
      height: 570px;
      overflow-y: auto;

      .bottom {
        margin-top: 45px;
        font-size: 14px;
        width: 100%;
        padding: 0 20px;
        display: flex;
        line-height: 40px;
        justify-content: space-between;
      }

      .bottom button {
        width: 114px;
        background-color: #fff;
        height: 40px;
        border: 1px solid #797979FF;
        border-radius: 4px;
      }

    }

  }
}

.suggest {
  height: 900px;
  font-size: 14px;

  .people_details {
    margin-top: 20px;
    width: 340px;
    display: flex;
    justify-content: space-between;
  }

  .basic {
    margin-bottom: 30px;
  }

  .margins {
    margin-top: 20px;
  }

  .bottoms {
    margin-top: 10px;
    width: 880px;
    display: flex;
    justify-content: space-between;
  }

  .last {
    flex: 1;
    width: 220px;
    height: 200px;
  }
}

.refer {
  width: 100%;
  text-align: center;
  color: #7F7F7F;
  font-size: 13px;
  margin-top: 40px;
}

.more_title {
  font-size: 13px;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
  padding-left: 20px;
  margin-bottom: 20px;
  color: #FFFFFF;
  background-color: $colorMain;
}

.operate {
  cursor: pointer;
}

.el-button--text {
  color: $colorMain;
}
</style>